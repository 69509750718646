/* src/App.css */
.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  font-family: Blinker, Verdana;
  padding-left: 20px;
  padding-right: 20px;
}

.form-container {
  max-width: 800px;
}

h1 {
  color: #2b4e8c;
  margin-bottom: 20px;
}

p {
  text-align: center;
  color: #555;
  max-width: 500px;
}

.form-group {
  margin-bottom: 15px;
}

.email-input {
  width: 300px;
  padding-inline: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

label {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
}

label input {
  margin-right: 10px;
}

.submit-button {
  background-color: #2b4e8c;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button:disabled {
  background-color: #ccc;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.flex-row {
  display: flex;
}

.gap-8 {
  gap: 8px;
}

.text-start {
  text-align: left;
}

.text-center {
  text-align: center
}

.button {
  background: #143D8D;
  color: var(--Shades-White, #FFF);

/* Paragraph/LG/Semi Bold */
font-family: Blinker;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 27px */
display: flex;
padding: 6px 12px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 100px;
}


.custom-checkbox {
  display: none;
}

.custom-checkbox + label {
  display: inline-block;
  width: 15px; /* Smaller width */
  height: 15px; /* Smaller height */
  background-color: #f0f0f0;
  border-radius: 3px;
  border: 2px solid #ccc;
  position: relative;
  cursor: pointer;
}

.custom-checkbox:checked + label {
  background-color: #2b4e8c; /* Change the color here */
  border-color: #2b4e8c;
}

.custom-checkbox:checked + label:before {
  content: '';
  position: absolute;
  top: 1px; /* Adjusted for smaller size */
  left: 3px; /* Adjusted for smaller size */
  width: 5px; /* Smaller checkmark */
  height: 9px; /* Smaller checkmark */
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.image-container {
  height: 156px; 
  display:flex; 
  justify-content:center
}

.breach-count-container {
  border-radius: 20px;
border: 1px solid var(--Neutral-100, #CDD0D6);
/* Shadow (MD) */
box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.05);
overflow: hidden;
margin-top: 20px;
}

.text-red{
  color: #B91C1C;
}

.text-72 {
  font-size: 72px;
}

.text-28 {
  font-size: 28px;
}

.breach-info-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.gap-20 {
  gap: 20px
}
.margin-0 {
margin:0
}

.quick-action-container {
  background-color: #F1F2F4;
  padding: 32px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.p-32{
  padding: 32px;
}

.center {
  display: flex;
  justify-content: center;
}

.loader {
  width: 20px;
  height: 20px;
  border: 2px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 

  .flex-grow{
    flex-grow: 1;
  }

  .no-underline {
    text-decoration: none;
  }

@media (max-width: 500px) {
  .email-input {
    width: auto;
    padding: 8px 16px;
  }

  .form-group.email-section {
    flex-direction: column;
  }

  .form-group button {
    width: 100%;
  }
}